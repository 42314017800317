<template>
    <admin-table title="系统日志" @search="handleSearch" :table="table" :loading="loading">
        <div slot="buttons">
            <el-button type="default" size="small" @click="handleExport">导出Excel</el-button>
        </div>
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column :align="v.align||'center'"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
    </admin-table>
</template>


<script>
    export default {
        data() {
            function datetimeFormat(v) {
                let date = new Date(v.createdAt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            }

            return {
                loading: false,
                table: {
                    search: [
                        {
                            type: 'select',
                            key: 'area_id',
                            data: [
                                {
                                    label: '全部',
                                    value: ''
                                }
                            ],
                            class: 'hidden-md-and-down',
                            placeholder: '请选择来源'
                        },
                        {
                            type: 'input',
                            key: 'keywords',
                            placeholder: '请输入关键词'
                        }
                    ],
                    columns: [
                        {title: '时间', field: 'createdAt', width: '200', formatter: datetimeFormat},
                        {title: '用户', field: 'username', width: '120'},
                        {title: '来源', field: 'tag', width: '120'},
                        {title: '内容', field: 'content', width: '', align:'left'},
                    ],
                    data: [],
                    total: 0,
                }
            }
        },
        methods: {
            async getData(params) {
                this.loading = true
                const resp = await this.$http.get(`/oplog/`, {params: params})
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.data
                    this.table.total = resp.data.data.total
                }
                this.loading = false
            },
            handleSearch(e) {
                this.getData(e)
            },
            handleExport() {
                let url = `/oplog/export/xlsx`
                this.$http.get(url, {
                    responseType: 'arraybuffer',
                    xsrfHeaderName: 'Authorization',
                }).then(res => {
                    const filename = res.headers["content-disposition"]
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', filename.split("filename=")[1])
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

